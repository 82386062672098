import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { Materials } from "../models/materials.model";

@Injectable()
export class MaterialsApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("material", http);
    }

    public getAll(): Observable<Materials[]> {
        return this.get("", { type: Materials });
    }
}
