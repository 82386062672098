import { Injectable } from "@angular/core";
import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { lastValueFrom } from "rxjs";
import { SearchTicketsDto } from "./dto/search-tickets.dto";
import { Tickets } from "./models/tickets.model";
import { TicketApi } from "./ticket.api";

@Injectable()
export class TicketTypeaheadApi extends NiceAsyncTypeaheadProvider<Tickets> {
    public resource = "tickets";

    private pageSize = 20;

    constructor(private ticketApi: TicketApi) {
        super();
    }

    public getById(id: number): Promise<Tickets> {
        return lastValueFrom(this.ticketApi.getById(id));
    }

    public async search(
        searchQuery: string,
        page: number,
        options: SearchTicketsDto
    ): Promise<NiceAsyncTypeaheadSearchResult<Tickets>> {
        const dto: SearchTicketsDto = {
            ...options,
            query: searchQuery,
            page,
            length: this.pageSize
        };

        const result: Tickets[] = await lastValueFrom(this.ticketApi.search(dto));

        return {
            items: result,
            nextPage: result.length === this.pageSize ? page + 1 : null
        };
    }

    public format(ticket: Tickets): string {
        return `#${ticket.batchNumber}` ?? "-";
    }
}
