import { NiceNavigationItem } from "@recursyve/nice-ui-kit.v2";

export const adminNavigation: NiceNavigationItem[] = [
    {
        id: "home",
        type: "group",
        children: [
            {
                id: "bin",
                title: "navigation.dumpsters",
                type: "basic",
                iconType: "fontawesome",
                icon: "fad fa-dumpster",
                link: "/dashboard/bin"
            },
            {
                id: "dashboard",
                title: "navigation.tickets",
                type: "basic",
                iconType: "fontawesome",
                icon: "fad fa-stream",
                link: "/dashboard/ticket"
            },
            {
                id: "dashboard",
                title: "navigation.shipping_order",
                type: "basic",
                iconType: "fontawesome",
                icon: "fad fa-dolly",
                link: "/dashboard/shipping-order"
            },
            {
                id: "dashboard",
                title: "navigation.spreader",
                type: "basic",
                iconType: "fontawesome",
                icon: "fa-duotone fa-shower-down",
                link: "/dashboard/spreader"
            },
            {
                id: "dashboard",
                title: "navigation.grain_box",
                type: "basic",
                iconType: "fontawesome",
                icon: "fa-duotone fa-toolbox",
                link: "/dashboard/grain-box"
            },
            {
                id: "dashboard",
                title: "navigation.nurse",
                type: "basic",
                iconType: "fontawesome",
                icon: "fa-duotone fa-bars-staggered",
                link: "/dashboard/nurse"
            },
            {
                id: "dashboard",
                title: "navigation.vapam",
                type: "basic",
                iconType: "fontawesome",
                icon: "fa-duotone fa-tractor",
                link: "/dashboard/vapam"
            }
        ]
    }
];
