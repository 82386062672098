import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { catchError, Observable } from "rxjs";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastService: NiceToastService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request.clone()).pipe(
            catchError(e => {
                const err = this.normalizeError(e, request.url);

                if (err) {
                    throw err;
                }

                throw e;
            })
        );
    }

    private normalizeError(error: any, api: string): any {
        if (error instanceof Error) {
            return error;
        }

        if (error instanceof HttpErrorResponse) {
            return this.handleHttpError(error, api);
        }
    }

    private handleHttpError(error: HttpErrorResponse, api: string) {
        if (error.status === 401) {
            this.router.navigate(["/login"]);
            return null;
        } else if (error.status === 403) {
            this.router.navigate(["/dashboard/forbidden"]);
            this.toastService.warning("", "403");
            return null;
        }

        if (error.status === 404) {
            return error;
        }

        return error;
    }
}
